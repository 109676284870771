import React from 'react';
import { Header, Footer } from './components';
import './styles/scss/application.scss';

function App() {
    return (
        <div id="wrapper">
            <Header />
            <Footer />
        </div>
    );
}

export default App;
